import React, { useState, useEffect, useCallback } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    Button,
    TableBody,
    TableContainer,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton, ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { getSurveyPdf, getImageUrl } from '../modules/surveydata';
import { useDispatch } from 'react-redux';
import { flatMapDeep, isObject, uniqBy, map, clone } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Spinner from '../common/spinner';
import PropTypes from 'prop-types';
import Add from '@material-ui/icons/Add';
import AttachmentIcon from '@material-ui/icons/Attachment';
import RichTextEditor from '../common/richtexteditor';
import { getSurveyEnrichment } from '../modules/surveydata';
import { ArrowBackIos } from '@material-ui/icons';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const useStyles = makeStyles((theme) => ({
    gridMain: {
        marginLeft: '1em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
    },
    table: {
        width: '100%',
        marginBottom: '20px',
    },
    paper: {
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    imageList: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 150px)',
        justifyContent: 'center',
        gap: 16,
    },
    imageListItem: {
        maxWidth: 150,
        width: '100%',
    },
    image: {
        maxWidth: '100%',
        height: 'auto',
    },
    backArrow: {
        cursor: 'pointer',
    },
    actionButtonGrid: {
        paddingTop: '16px',
    },
}));

export default function SurveyView(props) {
    const [expanded, setExpanded] = useState(false);
    const [imageUrls, setImageUrls] = useState({});
    const [loading, setLoading] = useState(true);
    const [loadingEnrichment, setLoadingEnrichment] = useState(false);
    const [surveyEnrichment, setSurveyEnrichment] = useState(null);
    const useAccordion = props.notExpandable && props.notExpandable === true ? false : true;
    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useDispatch();

    const onExpand = () => {
        setExpanded(!expanded);
    };

    const onDownloadSurvey = async () => {
        await getSurveyPdf(props.survey.id, `${props.survey.name}.pdf`)(dispatch);
    };

    const onEditSurvey = () => {
        props.history.push('/survey/edit/' + props.survey.id);
    };

    const onCreateLocation = () => {
        props.history.push('/locations/edit/new?surveyId=' + props.survey.id);
    };

    const onViewLocation = () => {
        props.history.push('/locations/' + props.survey.locationId);
    };

    const fetchAllImageUrls = useCallback(
        async (imageIds) => {
            try {
                let imageUrlsClone = clone(imageUrls);
                for (const imageId of imageIds) {
                    if (!imageUrlsClone[imageId]) {
                        const response = await getImageUrl(imageId)(dispatch);
                        if (response) {
                            imageUrlsClone[imageId] = response.thumbUrl;
                        }
                    }
                }
                setImageUrls(imageUrlsClone);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching image urls:', error);
            }
        },
        [dispatch, imageUrls]
    );

    useEffect(() => {
        const getAllSurveyPictures = (survey) => {
            return flatMapDeep(survey, (value, key) => {
                // pictures contains array
                if (key === 'pictures' && Array.isArray(value)) {
                    return value;
                }
                // cover picture is single object, return as array
                if (key === 'coverPicture' && isObject(value)) {
                    return [value];
                }
                if (isObject(value)) {
                    return getAllSurveyPictures(value);
                }
                return [];
            });
        };

        if ((expanded || !useAccordion) && loading) {
            const pictures = getAllSurveyPictures(props.survey);
            const uniqPicures = uniqBy(map(pictures, 'imageId'));
            fetchAllImageUrls(uniqPicures);
        }
    }, [props.survey, expanded, fetchAllImageUrls, loading, useAccordion]);

    const fetchEnrichment = useCallback(
        async (surveyId) => {
            try {
                const enrichment = await getSurveyEnrichment(surveyId)(dispatch);
                setSurveyEnrichment(enrichment);
                setLoadingEnrichment(false);
            } catch (error) {
                console.error('Error fetching image urls:', error);
            }
        },
        [dispatch]
    );

    useEffect(() => {
        if ((expanded || !useAccordion) && !loadingEnrichment && !surveyEnrichment) {
            setLoadingEnrichment(true);
            fetchEnrichment(props.survey.id);
        }
    }, [
        setLoadingEnrichment,
        expanded,
        fetchEnrichment,
        loadingEnrichment,
        props.survey.id,
        surveyEnrichment,
        useAccordion,
    ]);

    const mapSurveyUsage = (usage, propertyUsageOther) => {
        switch (usage) {
            case 1:
                return t('survey.usageOffice');
            case 2:
                return t('survey.usageProductionIndustrial');
            case 3:
                return t('survey.usageHousing');
            case 4:
                return t('survey.usageWarehouseOrLogistics');
            case 5:
                return t('survey.usageShopping');
            case 6:
                return t('survey.usageSports');
            case 7:
                return propertyUsageOther;
            default:
                return t('survey.undefined');
        }
    };

    const mapClassification = (classification) => {
        switch (classification) {
            case 0:
                return t('survey.undefined');
            case 1:
                return t('survey.classificationOda1');
            case 2:
                return t('survey.classificationOda2');
            case 3:
                return t('survey.classificationOda3');
            default:
                return t('survey.undefined');
        }
    };

    const mapFilterClass = (filterClass) => {
        switch (filterClass) {
            case 1:
                return 'ePM1';
            case 2:
                return 'ePM2,5';
            case 3:
                return 'ePM10';
            case 4:
                return 'Coarse';
            default:
                return t('survey.undefined');
        }
    };

    const mapFilterType = (filterType) => {
        switch (filterType) {
            case 1:
                return t('survey.filterTypeBag');
            case 2:
                return t('survey.filterTypeCartridge');
            case 3:
                return t('survey.filterTypeOther');
            case 4:
                return t('survey.filterTypeCassetteFilter');
            default:
                return t('survey.undefined');
        }
    };

    const mapHeatRecoveryModel = (heatRecoveryModel) => {
        switch (heatRecoveryModel) {
            case 1:
                return t('survey.heatRecoveryRotating');
            default:
                return t('survey.undefined');
        }
    };

    const onCancel = () => {
        props.history.goBack();
    };

    const SurveyViewDom = () => {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2>{t('survey.coverPicture')}</h2>
                    {props.survey.coverPicture ? (
                        <ImageListItem key={props.survey.coverPicture.imageId} className={classes.imageListItem}>
                            {loading ? (
                                <SkeletonLoader />
                            ) : (
                                <img
                                    src={`${imageUrls[props.survey.coverPicture.imageId]}`}
                                    alt={'img'}
                                    className={classes.image}
                                />
                            )}
                            <ImageListItemBar title={props.survey.coverPicture.comments} />
                        </ImageListItem>
                    ) : (
                        <Typography sx={{ fontFamily: 'italic' }}>{t('survey.coverPictureMissing')}</Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <h2>{t('survey.propertyDetails')}</h2>
                </Grid>
                <Grid item xs={12}>
                    {props.survey.comment}
                </Grid>
                <Grid item xs={12}>
                    <h3>{t('general.address')}</h3>
                    {props.survey.address ? (
                        <React.Fragment>
                            {props.survey.address.name}
                            <br />
                            {props.survey.address.streetAddress},&nbsp;
                            {props.survey.address.zipCode} {props.survey.address.city}
                        </React.Fragment>
                    ) : (
                        <span>{t('survey.noAddressInformation')}</span>
                    )}
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <h2>{t('survey.summary')}</h2>
                    {surveyEnrichment ? <RichTextEditor initialData={surveyEnrichment} viewOnly={true} /> : <Spinner />}
                </Grid>
                <Grid item xs={12}>
                    {t('survey.usage')}: {mapSurveyUsage(props.survey.usage, props.survey.propertyUsageOther)}
                    <br />
                    {t('survey.classification')}: {mapClassification(props.survey.classification)}
                </Grid>
                {props.survey.machineRooms && (
                    <React.Fragment>
                        <Grid item xs={12}>
                            <h2>{t('survey.machineRooms', { pcs: props.survey.machineRooms.length })}</h2>
                        </Grid>

                        {props.survey.machineRooms.map((machineRoom, machineRoomIndex) => (
                            <React.Fragment key={`machineroom_${machineRoomIndex}`}>
                                <Grid item xs={12}>
                                    <h3>{machineRoom.name}</h3>
                                </Grid>
                                {machineRoom.pictures && machineRoom.pictures.length > 0 && (
                                    <ImageList className={classes.imageList} cols={3}>
                                        {machineRoom.pictures.map((item) => (
                                            <ImageListItem key={item.imageId} className={classes.imageListItem}>
                                                {loading ? (
                                                    <SkeletonLoader />
                                                ) : (
                                                    <img
                                                        src={`${imageUrls[item.imageId]}`}
                                                        alt={'img'}
                                                        className={classes.image}
                                                    />
                                                )}
                                                <ImageListItemBar title={item.comments} />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                )}
                                {machineRoom.machines.length < 1 && (
                                    <Grid item xs={12}>
                                        {t('survey.noMachines')}
                                    </Grid>
                                )}
                                {machineRoom.machines.map((machine, machineIndex) => (
                                    <Grid item xs={12} key={`machineroom_${machineRoomIndex}_machine_${machineIndex}`}>
                                        <h3>{machine.name}</h3>
                                        {t('location.airVolume')}: {machine.airVolume} m2
                                        <br />
                                        {t('survey.filterClass')}: {mapFilterClass(machine.filterClass)} <br />
                                        {t('survey.filterEfficiency')}: {machine.filterEfficiency} % <br />
                                        {t('survey.filterType')}: {mapFilterType(machine.filterType)} <br />
                                        {t('survey.heatRecoveryModel')}:{' '}
                                        {mapHeatRecoveryModel(machine.heatRecoveryModel)} <br />
                                        {t('location.serviceArea')}: {machine.serviceArea} <br />
                                        {machine.filters.length === 0 ? (
                                            <em>{t('survey.containsNoFilters')}</em>
                                        ) : (
                                            <React.Fragment>
                                                <h3>{t('survey.usedFilterDimensions')}</h3>

                                                <TableContainer component={Paper}>
                                                    <Table
                                                        className={classes.table}
                                                        size="small"
                                                        aria-label="Filters"
                                                        sx={{ maxWidth: 650 }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>{t('survey.width')}</TableCell>
                                                                <TableCell>{t('survey.height')}</TableCell>
                                                                <TableCell>{t('survey.maxDepth')}</TableCell>
                                                                <TableCell>{t('survey.quantity')}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {machine.filters.map((filter, filterIndex) => (
                                                                <TableRow
                                                                    key={`machineroom_${machineRoomIndex}_machine_${machineIndex}_filter_${filterIndex}`}>
                                                                    <TableCell>{filter.dimensionWidth}</TableCell>
                                                                    <TableCell>{filter.dimensionHeight}</TableCell>
                                                                    <TableCell>{filter.maxDepth}</TableCell>
                                                                    <TableCell>{filter.count}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </React.Fragment>
                                        )}
                                        {machine.numberOfSeals > 0 && (
                                            <React.Fragment>
                                                <h3>{t('survey.usedSeals')}</h3>

                                                <TableContainer component={Paper}>
                                                    <Table
                                                        className={classes.table}
                                                        size="small"
                                                        aria-label="Seals"
                                                        sx={{ maxWidth: 650 }}>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>{t('survey.sealType')}</TableCell>
                                                                <TableCell>{t('survey.quantity')}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>{machine.selectedSeal}</TableCell>
                                                                <TableCell>{machine.numberOfSeals}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </React.Fragment>
                                        )}
                                        {machine.pictures && machine.pictures.length > 0 && (
                                            <ImageList className={classes.imageList} cols={3}>
                                                {machine.pictures.map((item) => (
                                                    <ImageListItem key={item.imageId} className={classes.imageListItem}>
                                                        {loading ? (
                                                            <SkeletonLoader />
                                                        ) : (
                                                            <img
                                                                src={`${imageUrls[item.imageId]}`}
                                                                alt={'img'}
                                                                className={classes.image}
                                                            />
                                                        )}
                                                        <ImageListItemBar title={item.comments} />
                                                    </ImageListItem>
                                                ))}
                                            </ImageList>
                                        )}
                                    </Grid>
                                ))}
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                )}
            </Grid>
        );
    };

    const ActionButtonsDom = () => {
        return (
            <Grid container spacing={2} className={classes.actionButtonGrid}>
                {!!props.survey.locationId && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="btn-view-location"
                            onClick={onViewLocation}
                            startIcon={<AttachmentIcon />}>
                            {t('general.location')}
                        </Button>
                    </Grid>
                )}
                {!props.survey.locationId && (
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="btn-create-location"
                            onClick={onCreateLocation}
                            startIcon={<Add />}>
                            {t('buttons.createLocation')}
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-survey-pdf"
                        onClick={onDownloadSurvey}
                        startIcon={<DownloadIcon />}>
                        PDF
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-survey-edit"
                        onClick={onEditSurvey}
                        startIcon={<EditIcon />}>
                        {t('buttons.edit')}
                    </Button>
                </Grid>
            </Grid>
        );
    };

    //TODO: move to separate component when needed elsewhere
    const SkeletonLoader = ({ width = 150, height = 150 }) => (
        <Skeleton variant="rectangular" width={width} height={height} animation="wave" />
    );

    // version for survey list using accordion component
    if (useAccordion) {
        return (
            <Accordion expanded={expanded} onChange={onExpand} key={props.survey.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <h3>{props.survey.name}</h3>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" color="textSecondary">
                                {t('survey.modifiedAt', {
                                    timeStamp: dayjs.utc(props.survey.updateDate).local().format('DD.MM.YYYY HH:mm'),
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container className={classes.gridMain} spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={1} className={classes.paper}>
                                <SurveyViewDom />
                                <ActionButtonsDom />
                            </Paper>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    }
    // without accordion element for single surveyview
    return (
        <Grid container>
            <Grid container>
                <Grid item xs={12}>
                    <h2>
                        <ArrowBackIos onClick={onCancel} className={classes.backArrow} name="close" />
                        <span data-testid="survey-name">{props.survey.name}</span>
                    </h2>
                </Grid>
            </Grid>
            <Grid container className={classes.gridMain} spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={1} className={classes.paper}>
                        <SurveyViewDom />
                    </Paper>
                </Grid>
            </Grid>
            <Footer>
                <ActionButtonsDom />
            </Footer>
            <FooterPadding />
        </Grid>
    );
}

SurveyView.protoTypes = {
    survey: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    notExpandable: PropTypes.bool,
};
