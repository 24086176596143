import OrderRow from './orderrow';
import Location from './location';
import ActionItem from './actionitem';
import Customer from './customer';
import Address from './address';
import ElectronicInvoiceInfo from './electronicinvoiceinfo';
import i18next from 'i18next';

function translate(key) {
    return i18next.t(key) ? i18next.t(key) : key;
}

class Order {
    constructor(json) {
        if (json != null) {
            this.id = json.id;
            this.storageId = json.storageId;
            this.status = json.status;
            if (json.location) this.location = new Location(json.location);
            if (json.customer != null) {
                this.customer = new Customer(json.customer);
            }
            this.orderedFilters = [];
            if (json.orderedFilters != null && json.orderedFilters.length > 0) {
                for (let f of json.orderedFilters) {
                    this.orderedFilters.push(new OrderRow(f));
                }
            }
            this.orderTotalPriceWithoutVat = json.orderTotalPriceWithoutVat;
            this.vatPercent = json.vatPercent;
            this.vatAmount = json.vatAmount;
            this.orderTotalPrice = json.orderTotalPrice;
            this.deliveryTime = json.deliveryTime;
            this.deliveryTerms = json.deliveryTerms;
            this.ordererName = json.ordererName;
            this.ordererTel = json.ordererTel;
            this.contractNumberId = json.contractNumberId;
            this.contractNumber = json.contractNumber;
            this.deliveryRecipient = json.deliveryRecipient;
            this.deliveryAddress = new Address(json.deliveryAddress);
            this.deliveryCustomerNumber = json.deliveryCustomerNumber;
            this.billingResponsible = json.billingResponsible;
            if (json.billingAddress) this.billingAddress = new Address(json.billingAddress);
            if (json.electronicInvoiceAddress)
                this.electronicInvoiceAddress = new ElectronicInvoiceInfo(json.electronicInvoiceAddress);
            this.billingResponsibleBusinessId = json.billingResponsibleBusinessId;
            this.billingResponsibleCustomerNumber = json.billingResponsibleCustomerNumber;
            this.orderSent = json.orderSent;
            this.orderForm = json.orderForm;
            this.reference = json.reference;
            this.confirmationEmail = json.confirmationEmail;
            if (json.action) this.action = new ActionItem(json.action);

            this.orderSender = json.orderSender;
            this.portfolioId = json.portfolioId;
            this.maintenanceArea = json.maintenanceArea;
            this.packingInstructions = json.packingInstructions;
            this.deliveryMethod = json.deliveryMethod;
            this.billingTerms = json.billingTerms;
            this.invoiceNumbers = json.invoiceNumbers;
            this.deliveryInfo = json.deliveryInfo;
            this.orderNumber = json.orderNumber;
            this.orderType = json.orderType;
            this.billingResponsibleVatId = json.billingResponsibleVatId;
            this.ourReference = json.ourReference;
            this.generalInfo = json.generalInfo;
            this.combinedOrderInfoBase64 = json.combinedOrderInfoBase64;
            this.usesCapacityAllocation = json.usesCapacityAllocation;
            this.earlyDeliveryTime = json.earlyDeliveryTime;
            this.emailInvoiceAddress = json.emailInvoiceAddress;
            this.orderToPrivateCustomer = json.orderToPrivateCustomer;
            this.deliveryAddressMustBeSelectedInCart = json.deliveryAddressMustBeSelectedInCart;
            this.deliveryTermsImmutable = json.deliveryTermsImmutable;
            this.invoicingInfoBase64 = json.invoicingInfoBase64;
        } else {
            this.id = 0;
            this.storageId = 0;
            this.status = 0;
            this.location = null;
            this.customer = null;
            this.orderedFilters = [];
            this.orderTotalPriceWithoutVat = 0;
            this.vatPercent = 0;
            this.vatAmount = 0;
            this.orderTotalPrice = 0;
            this.deliveryTime = '';
            this.deliveryTerms = '';
            this.ordererName = '';
            this.ordererTel = '';
            this.contractNumberId = 0;
            this.contractNumber = '';
            this.deliveryRecipient = '';
            this.deliveryAddress = null;
            this.deliveryCustomerNumber = null;
            this.billingResponsible = '';
            this.billingAddress = null;
            this.electronicInvoiceAddress = null;
            this.billingResponsibleBusinessId = '';
            this.billingResponsibleCustomerNumber = null;
            this.orderSent = null;
            this.orderForm = [];
            this.reference = '';
            this.confirmationEmail = '';
            this.maintenanceArea = '';
            this.packingInstructions = '';
            this.orderNumber = 0;
            this.deliveryAddressMustBeSelectedInCart = false;
            this.deliveryTermsImmutable = false;
        }
    }

    static StatusOrdered() {
        return 4;
    }
    static StatusDelivered() {
        return 8;
    }
    static StatusDeliveredNonInvoicable() {
        return 9;
    }

    orderStatusText() {
        switch (this.status) {
            case Order.StatusOrdered():
                return translate('general.ordered');
            case Order.StatusDelivered():
                return translate('general.delivered');
            case Order.StatusDeliveredNonInvoicable():
                return translate('general.deliveredNonInvoicable');
            default:
                return '';
        }
    }
    getDeliveryInfo() {
        return this.deliveryAddress != null
            ? `${this.deliveryRecipient}, ${this.deliveryAddress.streetAddress}, ${this.deliveryAddress.zipCode} ${this.deliveryAddress.city}`
            : null;
    }
    getBillingAddressInfo() {
        return this.billingAddress != null
            ? `${this.billingResponsible}, ${this.billingAddress.streetAddress}, ${this.billingAddress.zipCode} ${this.billingAddress.city}`
            : null;
    }
    getElectronicInvoiceAddressInfo() {
        return this.electronicInvoiceAddress != null
            ? `${this.electronicInvoiceAddress.ovt}, ${this.electronicInvoiceAddress.operatorBrokerId}, ${this.electronicInvoiceAddress.operator}`
            : null;
    }

    static OrderTypeEarly = () => 'Early';
    static OrderTypeNormal = () => 'Normal';
    static OrderTypeUrgent = () => 'Urgent';
}

export default Order;
