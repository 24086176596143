import moment from 'moment';
import i18next from 'i18next';

function translate(key) {
    return i18next.t(key) ? i18next.t(key) : key;
}

const getStatusText = (status) => {
    switch (status) {
        case 1:
            return translate('general.draft');
        case 2:
            return translate('invoice.invoiced');
        default:
            throw new Error('fail');
    }
};

class InvoiceDescription {
    constructor(json) {
        this.id = json.id;
        this.status = json.status;
        this.statusText = getStatusText(json.status);
        this.invoiceNumber = json.invoiceNumber;
        this.invoiceDate = moment(json.invoiceDate);
        this.dueDate = moment(json.dueDate);
        this.orderDate = moment(json.orderDate);
        this.payerName = json.payerName;
        this.payerNameExt = json.payerNameExt ? json.payerNameExt : '';
        this.payerNumber = json.payerNumber;
        this.vatExcluded = json.vatExcluded;
        this.originalInvoiceNumber = json.originalInvoiceNumber;
        this.salesInvoiceIntegration = json.salesInvoiceIntegration;
    }
}

export default InvoiceDescription;
