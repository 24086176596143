import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import { DialogActions, DialogContent, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getDeliveredOrdersFullData } from '../modules/orderdata';
import Spinner from '../common/spinner';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import { cloneDeep, remove, sortBy } from 'lodash';
const DEFAULT_SELECTION = '-';

const useStyles = makeStyles((theme) => ({
    oldinfo: {
        textDecoration: 'line-through',
    },
    padding: {
        paddingTop: '1em',
        paddingBottom: '1em',
    },
}));

export default function DeliveredOrderSelectorDialog(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const open = props.open;
    const confirm = props.confirm;
    const cancel = props.cancel;

    // TODO orders that are already on the invoice, shall reflect on what orders can be picked
    // - Jatkokehityksessä tehdään tämäkin, nyt ei.
    //const baseOrders = props.orders;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const [selectedOrders, setSelectedOrders] = React.useState([]);
    const [deliveredOrders, setDeliveredOrders] = React.useState(null);
    const [filteredOrderList, setFilteredOrderList] = React.useState(null);

    useEffect(() => {
        async function fetchOrders() {
            const resp = await getDeliveredOrdersFullData()(dispatch);
            if (resp) setDeliveredOrders(sortBy(resp, ['orderNumber']));
        }
        if (open && !deliveredOrders) {
            fetchOrders();
        }
        if (!open && deliveredOrders) {
            setDeliveredOrders(null);
        }
    }, [open, deliveredOrders, dispatch]);

    useEffect(() => {
        if (selectedOrders && deliveredOrders) {
            let newList = cloneDeep(deliveredOrders);
            if (selectedOrders.length > 0) {
                // filter selected ordernumber away
                remove(newList, (o) => selectedOrders.find((sel) => sel.orderNumber === o.orderNumber));
                // filter away orders with non-matching invoice info
                remove(
                    newList,
                    (o) => o.orders[0].invoicingInfoBase64 !== selectedOrders[0].orders[0].invoicingInfoBase64
                );
            }
            setFilteredOrderList(newList);
        }
    }, [selectedOrders, deliveredOrders]);

    useEffect(() => {
        if (!open && selectedOrders && selectedOrders.length) setSelectedOrders([]);
    }, [open, selectedOrders]);

    const onChange = (evt) => {
        const selected = filteredOrderList.find((o) => o.orderNumber === evt.target.value);
        if (!selected) throw new Error('error');

        setSelectedOrders([...selectedOrders, selected]);
    };

    const onRemoveFromSelected = (orderNumber) => {
        let newList = cloneDeep(selectedOrders);
        remove(newList, (o) => o.orderNumber === orderNumber);
        setSelectedOrders(newList);
    };

    if (open)
        return (
            <Dialog
                onClose={cancel}
                aria-labelledby="simple-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'md'}
                fullScreen={fullScreen}>
                <DialogContent dividers>
                    {filteredOrderList && (
                        <Grid container>
                            <Grid item xs={12}>
                                {t('invoice.selectOrdersForInvoice')}
                            </Grid>
                            <Grid item xs={12} className={classes.padding}>
                                {selectedOrders.map((o) => (
                                    <Grid container key={o.orderNumber}>
                                        <Grid item xs={11} alignContent="center">
                                            {o.orderNumber} - {o.orders[0].customer.name}
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton
                                                data-testid={'selected-order-item-' + o.orderNumber}
                                                aria-label="delete"
                                                color="primary"
                                                onClick={() => {
                                                    onRemoveFromSelected(o.orderNumber);
                                                }}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>

                            <Grid item xs={12}>
                                <Select
                                    required
                                    data-testid="order-selector"
                                    id="orderSelector"
                                    name="orderSelector"
                                    value={'-'}
                                    onChange={onChange}>
                                    <MenuItem value={DEFAULT_SELECTION}>-Valitse-</MenuItem>
                                    {filteredOrderList.map((opt) => (
                                        <MenuItem key={opt.orderNumber} value={opt.orderNumber}>
                                            {opt.orderNumber} - {opt.orders[0].customer.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    )}
                    {!filteredOrderList && (
                        <Grid container>
                            <Grid item xs={12}>
                                {t('general.retrievingInformation')}
                                <Spinner />
                            </Grid>
                        </Grid>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        name="btn-modal-confirm"
                        onClick={() => {
                            confirm(selectedOrders);
                        }}
                        disabled={!selectedOrders || !selectedOrders.length}>
                        {t('production.makeInvoice')}
                    </Button>
                    &nbsp;&nbsp;
                    <Button variant="contained" color="default" name="btn-modal-cancel" onClick={cancel}>
                        {t('buttons.cancel')}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    return null;
}

DeliveredOrderSelectorDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    confirm: PropTypes.func.isRequired,
    cancel: PropTypes.func.isRequired,
};
