import React, { useEffect, Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Location from '../domain/location';
import { getLocationFull, getOfferPrint, getFilterListPrint } from '../modules/locationdata';
import Spinner from '../common/spinner';
import PrintIcon from '@material-ui/icons/Print';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Checkbox,
    FormControlLabel,
    CircularProgress,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { filter } from 'lodash';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { createHtmlToPdf } from '../modules/pdfdata';
import { toast } from 'react-toastify';
import moment from 'moment';
import NumericInputElement, { TypeOnBlur } from '../common/numericinput';

const printSettingBoxProps = {
    bgcolor: 'background.paper',
    m: 1,
    border: 1,
    borderRadius: 4,
    displayPrint: 'none',
    padding: '5px',
    maxWidth: '1123px',
    margin: 'auto',
    marginBottom: '10px',
};

const printPreviewBoxProps = {
    maxWidth: '1123px',
    margin: 'auto',
    fontSize: '12px !important',
};

const useStyles = makeStyles((theme) => ({
    selectOption: {
        textAlign: 'center',
    },
    printBtn: {
        textAlign: 'center',
        marginTop: '10px',
    },
    progress: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export default function LocationPrintView(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const id = parseInt(props.match.params.locationId, 10);
    const locationsById = useSelector((state) => state.locationdata.locationsById);
    const getLocByIdInProgress = useSelector((state) => state.locationdata.getLocByIdInProgress);
    const getLocByIdFailed = useSelector((state) => state.locationdata.getLocByIdFailed);
    const printInProgress = useSelector((state) => state.locationdata.printInProgress);
    const printPreview = useSelector((state) => state.locationdata.printPreview);
    const [selectedTab, setSelectedTab] = useState(0);
    const [checkedPrintOptions, setCheckedPrintOptions] = useState(['selectionTotalPriceWithVat']);
    const [createPdfOnProgress, setCreatePdfOnProgress] = useState(false);
    const printoptions = [
        'selectionFilterPrices',
        'selectionTotalPriceWithVat',
        'selectionOnlyMachineInfo',
        'selectionOnlyProducts',
        'selectionAirVolume',
        'selectionMaterialSpeed',
    ];
    const [deliveryFee, setDeliveryFee] = useState('');
    const [location, setLocation] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [locationTotalFilterCount, setLocationTotalFilterCount] = useState(0);
    const [locationSelectedFilterCount, setLocationSelectedFilterCount] = useState([]);
    const { t, i18n } = useTranslation();
    const printLanguage = i18n.language;

    const getPdf = async () => {
        const fileDocPrefix = selectedTab === 0 ? t('general.offer') : t('location.offer.productList');
        const fileDate = moment().format('DD-MM-YYYY');
        setCreatePdfOnProgress(true);
        await loadPdf(`${fileDocPrefix}-${location.id}-${fileDate}.pdf`);
    };

    const loadPdf = async (fileName) => {
        const base64EncodedBody = Buffer.from(printPreview.body).toString('base64');
        const base64EncodedHeader = Buffer.from(printPreview.header).toString('base64');
        var result = await createHtmlToPdf(base64EncodedBody, base64EncodedHeader, fileName);
        if (result) {
            toast(t('createPdf.createPdfSuccess'), { timeout: 1000, hideProgressBar: true });
        } else {
            toast(t('createPdf.createPdfFail'), { autoClose: 5000 });
        }
        setCreatePdfOnProgress(false);
    };

    const handleOptionChange = (option) => {
        let newCheckedOptions = checkedPrintOptions.includes(option)
            ? checkedPrintOptions.filter((c) => c !== option)
            : [...checkedPrintOptions, option];
        //xor logic
        if (option === 'selectionOnlyMachineInfo') {
            newCheckedOptions = newCheckedOptions.filter((c) => c !== 'selectionOnlyProducts');
        }
        if (option === 'selectionOnlyProducts') {
            newCheckedOptions = newCheckedOptions.filter((c) => c !== 'selectionOnlyMachineInfo');
        }
        setCheckedPrintOptions(newCheckedOptions);
    };

    useEffect(() => {
        if (
            id !== undefined &&
            id !== null &&
            locationsById[id] == null &&
            !getLocByIdInProgress &&
            !getLocByIdFailed
        ) {
            getLocationFull(id)(dispatch);
        }
    }, [id, dispatch, getLocByIdFailed, locationsById, getLocByIdInProgress]);

    useEffect(() => {
        if (locationsById[id]) {
            let location = locationsById[id];
            setLocation(location);
            const newSelectedFilters = location.machines.map((m) => m.filters.map((f) => f.id)).flat();
            setSelectedFilters(newSelectedFilters);
            setLocationTotalFilterCount(newSelectedFilters.length);
            const newSelectedFilterCount = location.machines.flatMap((m) =>
                m.filters.map(({ id, count }) => ({ id, count }))
            );
            setLocationSelectedFilterCount(newSelectedFilterCount);
        }
    }, [locationsById, id]);

    useEffect(() => {
        let options = {
            filterPrices: checkedPrintOptions.includes('selectionFilterPrices'),
            totalPriceWithVat: checkedPrintOptions.includes('selectionTotalPriceWithVat'),
            listFilters: !checkedPrintOptions.includes('selectionOnlyMachineInfo'),
            listMachines: !checkedPrintOptions.includes('selectionOnlyProducts'),
            airVolume: checkedPrintOptions.includes('selectionAirVolume'),
            materialSpeed: checkedPrintOptions.includes('selectionMaterialSpeed'),
            totalPrice: true,
            productNames: true,
        };
        if (location) {
            if (selectedTab === 0) {
                let printLoc = new Location(location);
                for (let machine of printLoc.machines) {
                    machine.filters = filter(machine.filters, (f) => {
                        return selectedFilters.includes(f.id);
                    }).map((filter) => {
                        filter.count = locationSelectedFilterCount.find((i) => i.id === filter.id).count;
                        return filter;
                    });
                }
                dispatch(getOfferPrint(printLoc, deliveryFee, printLanguage, options));
            } else {
                dispatch(getFilterListPrint(location, printLanguage));
            }
        }
    }, [
        location,
        deliveryFee,
        selectedFilters,
        checkedPrintOptions,
        selectedTab,
        dispatch,
        printLanguage,
        locationSelectedFilterCount,
    ]);

    const onDeliverFeeChange = (e) => {
        let value = e.target.value.replace(',', '.');
        const regex = /^\d*[.]?\d*/;
        setDeliveryFee(value.match(regex)[0]);
    };

    if (getLocByIdInProgress) {
        return <Spinner padding="20px" title={t('general.loading')} />;
    } else if (!getLocByIdInProgress && getLocByIdFailed) {
        return (
            <div style={{ padding: '20px' }}>
                <Alert severity="error">{t('general.fetchFailedRetry')}</Alert>
            </div>
        );
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const filterSelect = (id) => {
        let newSelectedFilters = [...selectedFilters];
        const index = newSelectedFilters.indexOf(id);
        if (index === -1) {
            newSelectedFilters.push(id);
        } else {
            newSelectedFilters.splice(index, 1);
        }
        setSelectedFilters(newSelectedFilters);
    };

    const allFiltersSelected = () => {
        return selectedFilters.length === locationTotalFilterCount;
    };

    const selectAllFilters = () => {
        if (allFiltersSelected()) setSelectedFilters([]);
        else setSelectedFilters(location.machines.map((m) => m.filters.map((f) => f.id)).flat());
    };

    const filterCountChanged = (filterId, value) => {
        const newLocationSelectedFilterCount = locationSelectedFilterCount.map((filter) => {
            if (filter.id === filterId) {
                return { ...filter, count: value };
            }
            return filter;
        });
        setLocationSelectedFilterCount(newLocationSelectedFilterCount);
    };

    if (!location) {
        return <div></div>;
    }

    return (
        <div>
            <Box {...printSettingBoxProps}>
                <Paper square>
                    <Box display="flex" justifyContent="center" width="100%">
                        <Tabs
                            value={selectedTab}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleTabChange}
                            aria-label="disabled tabs example"
                            variant="scrollable"
                            scrollButtons="auto">
                            <Tab label={t('general.offer')} name="tab-offer" />
                            <Tab label={t('location.offer.productList')} name="tab-list" />
                        </Tabs>
                    </Box>
                </Paper>
                <Grid container justifyContent="center">
                    {selectedTab === 0 && (
                        <Fragment>
                            <Grid item xs={12} className={classes.selectOption}>
                                {printoptions.map((option) => (
                                    <FormControlLabel
                                        key={option.toString()}
                                        checked={checkedPrintOptions.includes(option)}
                                        control={<Checkbox />}
                                        onChange={() => handleOptionChange(option)}
                                        label={t(`location.offer.${option}`)}
                                    />
                                ))}
                            </Grid>

                            <Accordion name="location-options">
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    {t('location.offer.productSelectionAndDeliveryCosts', {
                                        selectedFilterCount: selectedFilters.length,
                                        totalFilterCountInLocation: locationTotalFilterCount,
                                    })}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Fragment>
                                            <Grid item xs={10}>
                                                <Checkbox
                                                    name={'select-filter-all'}
                                                    checked={allFiltersSelected()}
                                                    onClick={selectAllFilters}
                                                />
                                                {t('general.selectAll')}
                                            </Grid>
                                            {location.machines.map((machine) => (
                                                <Fragment key={machine.id}>
                                                    <Grid item xs={12} key={machine.id}>
                                                        <h5>{machine.name}</h5>
                                                    </Grid>
                                                    {machine.filters.map((filter) => (
                                                        <Fragment key={filter.id}>
                                                            <Grid item xs={10}>
                                                                <Checkbox
                                                                    name={`select-filter-${filter.id}`}
                                                                    checked={selectedFilters.includes(filter.id)}
                                                                    onClick={() => filterSelect(filter.id)}
                                                                />
                                                                {filter.product
                                                                    ? filter.product.productName
                                                                    : filter.bundle.name}
                                                            </Grid>
                                                            {selectedFilters.includes(filter.id) ? (
                                                                <Grid item xs={2}>
                                                                    <NumericInputElement
                                                                        id={filter.id}
                                                                        value={
                                                                            locationSelectedFilterCount.find(
                                                                                (i) => i.id === filter.id
                                                                            ).count
                                                                        }
                                                                        onChange={filterCountChanged}
                                                                        type={TypeOnBlur}
                                                                        min={1}
                                                                    />
                                                                </Grid>
                                                            ) : (
                                                                <Grid item xs={2}>
                                                                    {`${
                                                                        locationSelectedFilterCount.find(
                                                                            (i) => i.id === filter.id
                                                                        ).count
                                                                    } ${t('pcs')}`}
                                                                </Grid>
                                                            )}
                                                        </Fragment>
                                                    ))}
                                                </Fragment>
                                            ))}
                                            <Grid item xs={12}>
                                                <TextField
                                                    label={t('offerOrProductList.deliveryFeeWithoutVat')}
                                                    name="deliveryfee"
                                                    value={deliveryFee}
                                                    onChange={(e) => onDeliverFeeChange(e)}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                                    }}
                                                />
                                            </Grid>
                                        </Fragment>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Fragment>
                    )}
                    {selectedTab === 1 && (
                        <Grid item xs={12} className={classes.selectOption}>
                            <p>{t('location.offer.productListFromLocation')}</p>
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.printBtn}>
                        <Button
                            name="pdf"
                            onClick={() => getPdf()}
                            startIcon={<PrintIcon />}
                            endIcon={createPdfOnProgress ? <CircularProgress color={'secondary'} size={'1rem'} /> : ''}
                            variant="contained"
                            color="primary"
                            disabled={createPdfOnProgress}>
                            {t('buttons.downloadPdf')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <Box {...printPreviewBoxProps} name="printPreview">
                {printInProgress && (
                    <div className={classes.progress}>
                        <Spinner />
                    </div>
                )}
                <div className="content" dangerouslySetInnerHTML={{ __html: printPreview.body }}></div>
            </Box>
        </div>
    );
}
