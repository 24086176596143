import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import * as invActions from '../modules/invoicedata';
import Spinner from '../common/spinner';
import InvoiceView from './invoiceview';
import { formatCurrency } from '../common/common';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getMoreInvoicesSent, getInvoicesOpen, invoiceFromOrders, RESET_INVOICE_DATA } from '../modules/invoicedata';
import Button from '@material-ui/core/Button';
import FooterPadding from '../main/footerpadding';
import Footer from '../main/footer';
import AddIcon from '@material-ui/icons/Add';
import DeliveredOrderSelectorDialog from './deliveredorderselectordialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
const _ = require('lodash');

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        marginTop: '5px',
        marginBottom: '5px',
    },
    invoiceHeader: {
        padding: '15px',
        fontSize: '1.1em',
        fontWeight: 'bold',
    },
    fieldLabel: {
        fontWeight: 'bold',
    },
    addMarginTop: {
        marginTop: '10px',
    },
}));

export default function InvoiceListView(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const allowedEInvOperatorsNetvisor = props.allowedEInvOperatorsNetvisor;
    const vatCodes = props.vatCodes;
    const invoicesOpen = useSelector((state) => state.invoicedata.invoicesOpen);
    const invoicesSent = useSelector((state) => state.invoicedata.invoicesSent);
    const loadingInvoicesOpen = useSelector((state) => state.invoicedata.loadingInvoicesOpen);
    const loadingInvoicesSent = useSelector((state) => state.invoicedata.loadingInvoicesSent);
    const invoicesById = useSelector((state) => state.invoicedata.invoicesById);
    const searchInProgress = useSelector((state) => state.invoicedata.searchInProgress);
    const [viewData, setViewData] = React.useState([]);
    const [orderSelectorOpen, setOrderSelectorOpen] = React.useState(false);
    const dispatch = useDispatch();
    const [invoicesToShow, setInvoicesToShow] = React.useState(50);
    const [invoiceIdExpanded, setInvoiceIdExpanded] = React.useState(null);

    useEffect(() => {
        if (!invoicesSent && !loadingInvoicesSent) {
            dispatch(getMoreInvoicesSent(0, 2000)); // TODO FIX hard-coded value for fetching..
        }
        if (!invoicesOpen && !loadingInvoicesOpen) {
            dispatch(getInvoicesOpen());
        }
    }, [invoicesSent, invoicesOpen, loadingInvoicesOpen, loadingInvoicesSent, dispatch]);

    useEffect(() => {
        if (!loadingInvoicesOpen && !loadingInvoicesSent && !searchInProgress) {
            let invoiceList = [];

            if (props.filter && props.filter.length >= 3) {
                // näytetään hakutulokset
                if (props.viewOnlyDraft) invoiceList = props.searchResults.filter((inv) => inv.status === 1);
                else invoiceList = props.searchResults;
            } else if (props.viewOnlyDraft) {
                // ei hakutuloksia, oletuksena avoimet laskut näkyville
                invoiceList = invoicesOpen;
            } else {
                // kaikki laskut näkyville
                invoiceList = invoicesSent;
            }
            if (invoiceList) {
                /* allData.splice(invoicesToShow, allData.length - invoicesToShow); */
                const newViewData = _.orderBy(invoiceList, ['invoiceNumber'], 'desc');
                setViewData(newViewData);
            }
        }
    }, [
        props.viewOnlyDraft,
        props.searchResults,
        props.filter,
        invoicesOpen,
        invoicesSent,
        loadingInvoicesOpen,
        loadingInvoicesSent,
        searchInProgress,
        invoicesToShow,
    ]);

    // Tänne tullaan kun lasku on lähetetty
    const onInvoiceStatusChange = () => {
        dispatch({ type: RESET_INVOICE_DATA });
    };

    const loadMoreSentInvoices = () => {
        if (invoicesSent && invoicesSent.length > 0) {
            //dispatch(getMoreInvoicesSent(invoicesSent.length, 50));
            setInvoicesToShow(invoicesToShow + 50);
        }
    };

    const createInvoiceFromOrders = (orders) => {
        const orderNumbers = orders.map((o) => o.orderNumber);
        dispatch(invoiceFromOrders(orderNumbers));
        setOrderSelectorOpen(false);
    };

    const onExpandInvoice = (invoiceId) => {
        if (!invoicesById[invoiceId]) {
            dispatch(invActions.getInvoiceById(invoiceId));
        }
        if (!invoiceIdExpanded) setInvoiceIdExpanded(invoiceId);
        else setInvoiceIdExpanded(null);
    };

    if (searchInProgress)
        return (
            <div className={classes.root}>
                <Grid container spacing={0} direction="column" alignItems="center" style={{ minHeight: '10vh' }}>
                    <Spinner size={'2rem'} />
                </Grid>
            </div>
        );

    if (props.filter && props.filter.length >= 3 && props.searchResults.length === 0) {
        return (
            <div className={classes.root}>
                <Grid container spacing={0} direction="column" alignItems="center" style={{ minHeight: '10vh' }}>
                    <Grid item>{t('general.noSearchResults')}</Grid>
                </Grid>
            </div>
        );
    }

    return (
        <div className={classes.root}>
            <Grid container>
                {viewData && viewData.length > 0 && (
                    <Grid item xs={12}>
                        <Paper variant="outlined" className={classes.paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {t('invoice.invoiceNumber')}/{t('general.state')}
                                        </TableCell>
                                        <TableCell>{t('invoice.payer')}</TableCell>
                                        <TableCell>{t('invoice.invoiceDate')}</TableCell>
                                        <TableCell>{t('invoice.totalPriceVatZero')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {viewData.map((inv) => (
                                        <React.Fragment key={inv.id}>
                                            <TableRow key={inv.id} onClick={() => onExpandInvoice(inv.id)}>
                                                <TableCell>
                                                    {inv.invoiceNumber}&nbsp;
                                                    <Chip
                                                        size="small"
                                                        color={inv.status === 1 ? 'primary' : 'secondary'}
                                                        label={inv.statusText}
                                                    />
                                                    &nbsp;
                                                    {inv.originalInvoiceNumber && (
                                                        <Chip size="small" color="primary" label="Hyvitys"></Chip>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {inv.payerName}
                                                    {inv.payerNameExt && inv.payerNameExt.length > 0 && (
                                                        <span>
                                                            <br />
                                                            {inv.payerNameExt}
                                                        </span>
                                                    )}
                                                </TableCell>
                                                <TableCell>{inv.invoiceDate.format('DD.MM.YYYY')}</TableCell>
                                                <TableCell>{formatCurrency(inv.vatExcluded)}</TableCell>
                                                <TableCell align="right">
                                                    {invoiceIdExpanded && invoiceIdExpanded === inv.id ? (
                                                        <ExpandLessIcon />
                                                    ) : (
                                                        <ExpandMoreIcon />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                            {invoiceIdExpanded && invoiceIdExpanded === inv.id && (
                                                <TableRow key={inv.id + 'fulldata'}>
                                                    <TableCell colSpan={4}>
                                                        {!invoicesById[inv.id] && <Spinner />}
                                                        {!!invoicesById[inv.id] && (
                                                            <Grid item>
                                                                <InvoiceView
                                                                    salesInvoiceIntegrationInUse={
                                                                        props.salesInvoiceIntegrationInUse
                                                                    }
                                                                    invoice={invoicesById[inv.id]}
                                                                    history={props.history}
                                                                    onStatusChange={onInvoiceStatusChange}
                                                                    allowedEInvOperatorsNetvisor={
                                                                        allowedEInvOperatorsNetvisor
                                                                    }
                                                                    vatCodes={vatCodes}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                    </Grid>
                )}
                {!props.viewOnlyDraft && (
                    <Grid item xs={12} className={classes.addMarginTop}>
                        <Button
                            variant="contained"
                            color="secondary"
                            name="newitem"
                            onClick={loadMoreSentInvoices}
                            disabled={
                                loadingInvoicesOpen ||
                                loadingInvoicesSent ||
                                searchInProgress ||
                                (props.filter && props.filter.length > 3)
                            }>
                            {t('general.showMore')}
                        </Button>
                    </Grid>
                )}
                {(loadingInvoicesOpen || loadingInvoicesSent || searchInProgress) && (
                    <Grid container spacing={0} direction="column" alignItems="center" style={{ minHeight: '20vh' }}>
                        <Grid item>
                            <Spinner size={'2rem'} />
                        </Grid>
                    </Grid>
                )}
                <Footer>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="create-invoice"
                            onClick={() => {
                                setOrderSelectorOpen(true);
                            }}
                            startIcon={<AddIcon />}
                            disabled={false}>
                            {t('general.invoice')}
                        </Button>
                    </Grid>
                </Footer>
                <FooterPadding />

                <DeliveredOrderSelectorDialog
                    open={orderSelectorOpen}
                    confirm={(orders) => {
                        createInvoiceFromOrders(orders);
                    }}
                    cancel={() => {
                        setOrderSelectorOpen(false);
                    }}
                />
            </Grid>
        </div>
    );
}
